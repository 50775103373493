@import "variables";
@import "variables-granteam";

.superscript {
    padding: 5px 17px 5px;
    outline: 0px solid transparent;
    border-radius: 0.25rem;
    transition: 0.15s;
    width: 100%;
    //height: 36px;
    //max-height: 36px;
    min-height: 36px;
    overflow-x: auto;
    overflow-y: hidden;
}

.superscript.disabled {
    background-color: #e9ecef !important;
    border-color: #e6ecf0 !important;
    color: #495057 !important;
}

.w100-r110 {
    width: calc(100% - 110px);
}

.superscript-help {
    position: absolute;
    width: 120px;
    right: 0px;
    text-align: center;
    top: -16px;
    color: #999;
}

.opacity-anim {
    color: #777;
    animation: expandIcons 0.2s ease-in-out;
}

@keyframes expandIcons {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.superscript-controls {
    width: 110px;
    position: absolute;
}

html {
    // LIGHT MODE
    &:not(.rui-night-mode) {
        .superscript {
            border: 1px solid $color_separator;

            sup,
            sub {
                color: darkgoldenrod;
            }

            &::after {
                content: attr(title);
                position: absolute;
                cursor: text;
                color: #999;
            }

            &:focus {
                border-color: $gt-border-color !important;
                box-shadow: $gt-form-box-shadow;
                -webkit-box-shadow: $gt-form-box-shadow;
            }

            &.is-invalid {
                background-color: #fef9fa;
                border-color: #fac6cc;

                &:focus {
                    -webkit-box-shadow: 0 0 0 0.2rem rgba($color_danger, 0.15);
                    box-shadow: 0 0 0 0.2rem rgba($color_danger, 100);
                }
            }
        }
    }

    // NIGHT MODE
    &.rui-night-mode {
        .superscript {
            background-color: #292f38;
            border: 1px solid #454c55;

            sup,
            sub {
                color: goldenrod;
            }

            &::after {
                content: attr(title);
                position: absolute;
                cursor: text;
                color: #777;
            }

            &:focus {
                border-color: $gt-purple-3;
                -webkit-box-shadow: 0 0 0 0.2rem rgba($gt-purple-3, 0.2);
                box-shadow: 0 0 0 0.2rem rgba($gt-purple-3, 0.2);
            }
        }
    }
}
