.demo > .modal {
    background-color: rgba($color_dark_4, .85);
}
.demo-icons {
    color: $color_secondary;
    background-color: $color_dark_2;
    border-color: $color_dark_2;

    > .rui-icon {
        stroke: $color_text_1;
    }

    &:hover {
        background-color: $color_dark_3;
        border-color: $color_dark_4;
    }
}
