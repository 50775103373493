@import "variables-granteam";

.gt-input-switch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: $gt-purple-3 !important;
}

.gt-input-switch-container {
    min-width: 95px;
    margin-bottom: 16px;
    label {
        margin: 0px;
        line-height: 36px;
    }
    .input-switch-container {
        display: flex;
        align-items: center;
    }
}
