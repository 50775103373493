@import '../../../common-assets/css/variables';

.similar-wrapper {
    position: absolute;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding: 5px 5px;
    background-color: #fafafa;
    border: 1px solid $color_success;
    border-radius: 5px;
    z-index: 1000000;

    & > .similar-option {
        margin: 0 5px;
        color: $color_danger;
    }
}