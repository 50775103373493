@import "variables";

.hide-button-dummy-container {
    position: relative;

    .hide-button-container {
        position: absolute;
        top: -16px;
        right: -15px;
        z-index: 200;
        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            background-color: $color_grey_6;
            border-color: $color_grey_6;
            transition: all 0.2s ease-in;

            &:active:not(.hidden-active) {
                background-color: $color_grey_6;
                border-color: $color_grey_6;
            }

            &:hover {
                scale: 1.1;
            }

            &:focus,
            &:focus:active {
                outline: none;
                box-shadow: none;
            }

            &.hidden-active {
                background-color: $color_danger;
                border-color: $color_danger;
                &:hover {
                    scale: 1.1;
                }
            }
        }
    }
}
