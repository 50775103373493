.checkbox-form {
    width: 100%;
}

.custom-checkbox {
    width: 3rem;
}

.checkbox-label {
    width: calc(100% - 3rem);
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
}

.custom-modal-checkbox-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .custom-modal-checkbox {
        margin: 0px 2rem;
    }

    .p-checkbox-box {
        border: 2px solid #7d6ac8 !important;
        outline: none !important;
        background-color: #fff !important;
        &.p-highlight {
            background-color: #7d6ac8 !important;
        }

        &:hover,
        &:focus,
        &.p-focus {
            box-shadow: 0 0 0 0.2rem rgba(#7d6ac8, 0.5) !important;
        }
    }
}
