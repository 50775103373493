@import "variables";
@import "variables-granteam";

$backgroundColor: $color_grey_1;
$fontColor: $gt-green-3;

.tiles-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.bs-tile {
    display: flex;
    float: left;
    width: 200px;
    height: 170px;
    margin: 20px;
    padding: 20px;

    border-radius: 20px;

    // border: 1px solid $fontColor;
    background-color: $backgroundColor;
    color: $fontColor;

    -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

    justify-content: space-between;
    align-items: center;
    text-align: center;

    cursor: pointer;

    flex-direction: column;
    font-size: 16px;
    transition: 0.3s;

    &:hover {
        background: $fontColor !important;
        color: $backgroundColor;
        svg {
            color: $backgroundColor;
        }
    }

    .tile-back-style {
        color: $fontColor;
        margin-bottom: 20px;
        svg {
            transition: 0.3s;
            height: 70px;
            width: auto;
        }
    }

    .tile-bottom-style {
        height: 40px;
        display: flex;
        align-items: center;
        // letter-spacing: 1px;
        font-family: "Nunito Sans";
        line-height: 20px;
    }
}

//mobile

.tiles-wrap.mobile {
    .bs-tile {
        height: 150px;
        width: 150px;
        margin: 10px;
        svg {
            height: 50px;
        }
    }
}
