@import "variables";

.input-popup-container {
    position: relative;
    width: inherit;

    .input-popup {
        position: absolute;
        width: inherit;
        z-index: 2;
        opacity: 0;
        transition: all 0.15s ease-in-out;
        margin-top: 3px;
        min-height: 50px;
        background-color: $white;
        visibility: hidden;
        border-radius: 3px;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

        &.opened {
            visibility: visible;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.prime-popup-input {
    height: 36px;
    & > button {
        width: 100%;
        border: none;
        margin: 0px !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        justify-content: center;
        align-items: center;
        background-color: $color_grey_5;
        color: $color_text_2;
        transition: 200ms opacity;
        &:hover {
            opacity: 0.8;
            background-color: $color_grey_5;
            border: none;
            color: $color_text_2;
        }
        &:focus:hover {
            background-color: $color_grey_5;
            color: $color_text_2;
        }
        &:active {
            background-color: $color_grey_5 !important;
            color: $color_text_2 !important;
        }
        &:focus {
            box-shadow: none !important;
        }
    }

    &.p-inputtext {
        width: 100%;
        border-color: $color_separator;
        padding: 9px 17px;
        &.is-invalid {
            background-color: #fef9fa;
            border-color: #fac6cc;
        }
        &:hover {
            border-color: $color_separator !important;
        }
        &:focus {
            border-color: $gt-border-color !important;
            box-shadow: $gt-box-shadow !important;
        }
    }
}
