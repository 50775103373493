//If you want to add new font we have to host it locally, check https://gwfh.mranftl.com/fonts for more fonts files with css

/* pt-serif-regular - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "PT Serif";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/pt-serif/pt-serif-v18-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* pt-serif-italic - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "PT Serif";
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/pt-serif/pt-serif-v18-cyrillic_cyrillic-ext_latin_latin-ext-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-300 - cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/open-sans/open-sans-v35-cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese-300.woff2")
        format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-300italic - cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/open-sans/open-sans-v35-cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese-300italic.woff2")
        format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-regular - cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/open-sans/open-sans-v35-cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese-regular.woff2")
        format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-600 - cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/open-sans/open-sans-v35-cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese-600.woff2")
        format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-600italic - cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    src: url("../fonts/open-sans/open-sans-v35-cyrillic_cyrillic-ext_greek_greek-ext_hebrew_latin_latin-ext_vietnamese-600italic.woff2")
        format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* nunito-sans-300 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/nunito-sans/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-sans-300italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/nunito-sans/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-sans-regular - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/nunito-sans/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
