@import "variables";

.collapse-toggle-button {
    display: flex;
    justify-content: center;
    padding: 2px 15px;
    width: fit-content;
    margin: auto;
    margin-bottom: 10px;
    margin-top: -10px;
    border-radius: 3px;

    transition: 0.2s;

    &:hover {
        background-color: $gray-200;
    }

    &.opened {
        span {
            transform: rotate(180deg);
            transition: 0.2s;
        }
    }
    &.closed {
        span {
            transform: rotate(0deg);
            transition: 0.2s;
        }
    }
}

.sampling-location-select-row {
    position: relative;
    margin-bottom: 20px;

    .sampling-location-modal-button {
        position: absolute;
        right: 60px;
        top: 50%;

        padding: 3px;
        border-radius: 50%;

        transform: translateY(-50%);
        transition: 0.2s;

        color: $color_grey_5;

        font-size: 2rem !important;

        &:hover {
            color: $color_dark_7;
        }
    }
    &.filled {
        .sampling-location-modal-button {
            right: 68px;
        }
    }
}
