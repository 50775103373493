@import "variables";
@import "variables-night";
@import "../common-assets/fonts/fonts.scss";

.react-datepicker-wrapper {
    width: 100%;
}

.custom-switch {
    label {
        &::before,
        &::after {
            cursor: pointer;
        }
    }
}

.snap-right {
    float: right;
}

// suneditor, create new override file for it?
.se-wrapper {
    cursor: auto;
}

.disabled {
    cursor: default;
}

.display-none {
    display: none;
}

.break-all {
    word-break: break-all;
    max-height: unset !important;
}

.pointer {
    cursor: pointer !important;
}

.inline-block {
    display: inline-block;
}

.image-gallery-content:not(.fullscreen) .image-gallery-slide div {
    height: 300px;
    width: 500px;

    img {
        object-fit: cover;
        object-position: center center;
        width: 100%;
        height: 100%;
    }
}

.image-gallery {
    width: 500px;
}

.rdl-control {
    overflow: auto;
    outline: none;
    scrollbar-width: thin;
    //border: 1px solid #e6ecf0 !important;
    border: 1px solid $color_grey_2 !important;
}

.rdl-actions {
    button {
        border-color: #2fc787;
        outline: none !important;

        .fa {
            color: #2fc787;
        }

        &:focus {
            background-color: #2fc78733 !important;
            border-color: #2fc787 !important;
        }

        &:hover {
            background-color: #2fc787 !important;
            border-color: #2fc787 !important;

            .fa {
                color: white;
            }
        }
    }
}

.custom-gt-select.is-invalid {
    > div {
        background-color: #fef9fa;
        border-color: #fac6cc;

        &:hover {
            box-shadow: 0 0 0 0.2rem #fac6cc70;
        }
    }
}

$helper-cc: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 !default;
@each $item in $helper-cc {
    .cc-#{$item} {
        column-count: #{$item} !important;
    }
}

$helper-cg: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50 !default;
@each $item in $helper-cg {
    .cg-#{$item} {
        column-gap: #{$item}px !important;
    }
}

.dont-break {
    break-inside: avoid-column;
}

.rounded-border {
    border: 1px solid #e6ecf0;
    border-radius: 0.25rem;
}

.rdl-move {
    background-color: transparent !important;
}

html {
    scrollbar-width: thin;

    .modal-body {
        ::-webkit-scrollbar {
            width: 0.9em;
        }
    }

    ::-webkit-scrollbar {
        width: 1.2rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 30px;
    }

    &.rui-night-mode {
        .rdl-control {
            background-color: $color_dark_2;
            color: $color_grey_4;
            border-color: $color_dark_6 !important;
        }

        ::-webkit-scrollbar-thumb {
            background: $color_dark_3;
            border: 3px solid $color_dark_2;

            &:hover {
                background: $color_dark_5 !important;
            }
        }

        .rdl-control:hover {
            &::-webkit-scrollbar-thumb {
                background: $color_dark_4;
            }
        }

        .modal-body {
            .rdl-control:hover {
                &::-webkit-scrollbar-thumb {
                    background: $color_dark_4;
                }
            }
        }

        .highlight-scrollbar {
            &:hover {
                &::-webkit-scrollbar-thumb {
                    background: $color_dark_3;
                }
            }
        }
    }

    &:not(.rui-night-mode) {
        ::-webkit-scrollbar-thumb {
            background: $color_grey_2;
            border-radius: 30px;
            border: 3px solid white;

            &:hover {
                background: $color_grey_4 !important;
            }
        }

        .modal-body {
            .rdl-control:hover {
                &::-webkit-scrollbar-thumb {
                    background: $color_grey_3;
                }
            }
        }

        .highlight-scrollbar {
            &:hover {
                &::-webkit-scrollbar-thumb {
                    background: $color_grey_3;
                }
            }
        }
    }
}

.mw-580 {
    max-width: 580px;
}

//table
//pretty input
.input-value {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;

    &.error {
        color: red;
    }
    option {
        color: black;
    }
}
//pretty select
.input-value-choice {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
}

.dashboard-status {
    color: indianred;
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.25;
    }
}

.file-list-element {
    padding-bottom: 20px;
}

.rui-page-title {
    overflow: hidden;
}

.table-cell-select {
    width: 100%;

    span {
        display: none;
    }

    > div {
        border: 0;
        background-color: transparent;

        > div {
            padding: 0 !important;
        }
    }
}

.undefined {
    // fix your js :)
    content: "fix your js :)";
    background-color: fuchsia;
    background: fuchsia;
    border: 5px solid fuchsia;
}

div.rqd__menu {
    z-index: 1002;
}

$div-disabled-pattern: repeating-linear-gradient(-45deg, #22222205, #22222205 10px, #33333310 10px, #33333310 20px);

@keyframes div-disabled-anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@include media-breakpoint-down(xs) {
    .rui-page-title {
        .container-fluid {
            margin-left: 0 !important;
        }
    }

    .rdt_TableHeadRow,
    .rdt_TableRow {
        .rdt_TableCol,
        .rdt_TableCell {
            &:first-of-type {
                padding-left: calc(48px / 6);
            }
        }
    }

    .modal-content {
        .modal-header {
            padding: 20px;
        }
        .modal-body {
            padding: 20px;
        }
        .modal-footer {
            padding: 20px;
        }
    }
}

.w-100px {
    width: 100px;
}

.w-130px {
    width: 130px;
}

.K-Chem-Composer .K-Chem-Editor {
    background-color: #fff !important;
}

.modal-body {
    .rui-rc-tree {
        overflow: auto;
        height: 350px;
    }
}

@include media-breakpoint-down(sm) {
    .dropdown-menu.show {
        left: 10px !important;
        top: 75px !important;
        font-size: 1.1rem;

        .nav-link {
            padding: 6px 15px !important;

            .rui-nav-circle {
                width: 26px;
                height: 26px;
                top: 5px;
                left: 10px;
            }
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input.input-hide-arrows::-webkit-outer-spin-button,
input.input-hide-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.input-hide-arrows[type="number"] {
    -moz-appearance: textfield;
}

.show-chem-struct {
    white-space: nowrap;

    .custom-checkbox {
        display: inline-block;
    }
    .col-form-label {
        padding-left: 0;
    }
}

.pos-rel-t-5 {
    position: relative;
    top: 5px;
}

.tiles-container {
    padding: 15px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    overflow-y: auto;

    max-height: 700px;
}

.avoid-break-inside {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}

.navbar-collapse {
    background-color: $white;
}

.route-transition {
    animation-name: fadeIn;
    animation-duration: 0.3s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.gt-dashboard .rui-widget-content {
    overflow: auto;
    padding: 10px;
}

.mobile-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.required-label {
    margin-left: 3px;
    color: $color-danger;
}

.media-img {
    user-select: none;
}
