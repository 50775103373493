.rui-irs,
.rui-irs-double {
    .rui-irs-value {
        color: $color_secondary;
    }
    .irs-line {
        background-color: $color_dark_4;
    }
    .irs-bar {
        background-color: rgba(lighten($color_brand, 30%), .5);
    }
    .irs-min,
    .irs-max {
        color: $color_dark_6;
    }
    .irs-from,
    .irs-to,
    .irs-single {
        background-color: lighten($color_brand, 10%);
    }
    .irs-handle {
        > i:first-child {
            background-color: $color_brand;
        }
        &:hover > i:first-child {
            background-color: darken($color_brand, 10%);
        }
        &:active > i:first-child,
        &.state_hover > i:first-child {
            background-color: lighten($color_brand, 15%);
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba($color_brand, .2);
        }
    }
}
