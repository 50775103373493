@import "variables";
@import "variables-granteam";

.chatbot-container {
    position: fixed;

    z-index: 1005;

    right: 20px;
    bottom: 20px;

    &.opened .chatbot-content {
        max-height: 600px;
        width: 600px;
    }

    &.closing .chatbot-content {
        max-height: 0px;
        width: 0px;
        height: 600px;
    }

    &.minimized .chatbot-content {
        cursor: pointer;
        overflow: hidden;
        width: 50px;
        max-height: 50px;
        min-height: 50px;
        height: 600px;
        font-size: 0px !important;
        @include center-flex;
        will-change: width, max-height, font-size;

        .chatbot-header {
            font-size: 0rem;
        }

        &:hover {
            border-radius: 15px;
            transform: scale(1.05);
        }
    }

    .chatbot-content {
        background-color: $color_grey_1;
        box-shadow: $gt-box-shadow;
        border-radius: 25px;
        width: 0px;
        max-height: 0px;
        overflow: hidden;
        font-size: 16px;

        transition: all 0.45s ease-in-out, border-radius 0.2s ease-in-out, transform 0.2s ease-in-out;

        .chatbot-header {
            width: 100%;
            font-weight: 700;
            padding: 20px 20px 10px 20px;
            @include space-between-flex;

            .chatbot-buttons-container {
                @include center-flex;

                .chatbot-close-btn,
                .chatbot-mm-btn {
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    transition: all 0.15s ease-in-out;
                    @include center-flex;
                    @include hover($color_grey_4);
                }
            }

            .clear-filters-btn {
                @include center-flex;
                padding: 3px 20px;
                background-color: $gt-green-2;
                border-radius: 18px;
                height: 36px;
                font-size: 12px;
                font-weight: 500;
                color: $white;
                cursor: pointer;
            }
        }

        .chatbot-output-content {
            margin: 0 20px;
            background-color: $white;
            border-radius: 20px;
            padding: 5px;
            font-size: 12px;

            @include gt-border;

            ul {
                min-height: 448px;
                max-height: 448px;
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 0px;
                margin: 0px;
                list-style-type: none;
                display: flex;
                flex-direction: column;

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            .chatbot-output-item {
                margin: 0 10px 10px 10px;
                padding: 10px 15px;
                border-radius: 20px;
                background-color: $color_grey_1;
                max-width: 80%;
                text-wrap: wrap;
                word-break: break-all;
                transform: scale(1);
                svg {
                    position: relative;
                    top: 3px;
                }

                &:first-of-type {
                    margin-top: 10px;
                }

                &.user {
                    align-self: flex-end;
                    text-align: end;
                    background-color: $gt-green-2;
                    color: $white;
                }

                &.chat {
                    align-self: flex-start;
                }

                transition: all 0.2s ease-in-out;
                animation: appear 0.3s;

                @keyframes appear {
                    0% {
                        transform: scale(0);
                        font-size: 0;
                        border-radius: 50%;
                    }
                    50% {
                        font-size: 0;
                    }
                    100% {
                        font-size: 1rem;
                        transform: scale(1);
                        border-radius: 20px;
                    }
                }
            }
        }

        .chatbot-input-content {
            margin: 10px 20px 0 20px;
            padding-bottom: 20px;

            display: flex;

            > div,
            button {
                margin: 0px !important;
            }

            .record-btn {
                @include center-flex;
                margin-left: 10px !important;
                transition: 0.2s all ease-in-out;
                width: 38px;
                height: 39px;

                outline-color: rgba(#000, 0.2) !important;

                &.recording {
                    background-color: $color_danger;
                    border-color: $color_danger;
                    color: $white;
                    width: 100%;
                    margin: 0px !important;
                    animation: text-color 500ms linear;

                    @keyframes text-color {
                        0% {
                            color: $color_danger;
                        }
                        100% {
                            color: $white;
                        }
                    }
                }

                .o-hidden {
                    position: absolute;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    border-radius: 15px;
                    @include center-flex;

                    .ripple {
                        position: absolute;
                        display: block;
                        width: 400px;
                        height: 400px;
                        transform: scale(0);
                        background-color: #f02b42;
                        border-radius: 50%;
                        animation: ripple 1000ms linear;
                    }

                    @keyframes ripple {
                        to {
                            transform: scale(4);
                            opacity: 0;
                        }
                    }
                }

                &.loading {
                    width: 100%;
                    margin: 0px !important;
                    color: $white;
                    background-color: $gt-green-3;
                    border-color: $gt-green-3;
                }
            }

            &:has(.record-btn.loading),
            &:has(.recording) {
                .modal-input {
                    width: 0%;
                    padding: 0;
                    border: none;
                    opacity: 0;
                }
            }

            .modal-input {
                position: relative;

                width: 100%;
                transition: 0.2s all ease-out;
                border-color: $color_separator;
            }

            .modal-input,
            .record-btn {
                border-radius: 10px;
            }
        }
    }
}
.fa-bounce {
    animation-name: fa-bounce;
    animation-delay: var(--fa-animation-delay, 0s);
    animation-direction: var(--fa-animation-direction, normal);
    animation-duration: var(--fa-animation-duration, 1s);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

@keyframes fa-bounce {
    0% {
        transform: scale(1) translateY(0);
    }

    10% {
        transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
    }
    30% {
        transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
    }
    50% {
        transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
    }
    57% {
        transform: scale(1) translateY(var(--fa-bounce-rebound, -0.125em));
    }
    64% {
        transform: scale(1) translateY(0);
    }
    100% {
        transform: scale(1) translateY(0);
    }
}
