@import "variables";

.async-tasks-dropdown {
    .collapse-btn {
        white-space: nowrap !important;
        width: 305px;
        cursor: pointer;
        transition: background 0.2s ease-in-out;

        h4 {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &:hover {
            background: $color_grey_1;
        }

        .badge {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 7px;
            background-color: #725ec3 !important;
            color: white !important;
            border-radius: 50%;
            width: 30px;
            height: 30px;
        }

        &:not(:first-child) {
            h4 {
                border-top: 1px solid $color_separator;
            }
        }

        .icon {
            transition: all 0.15s ease-in;
            margin-right: 5px;
        }

        &:has(+ .async-tasks-collapse.collapse.show):has(li),
        &:has(+ .async-tasks-collapse.collapsing):has(li) {
            .open {
                border-bottom: 1px solid $color_separator;
            }
        }

        .open .icon {
            transform: rotate(90deg);
        }
    }

    .async-tasks-collapse {
        max-height: calc(40vh - 64px);

        .tasks-container {
            max-height: inherit;
            overflow-y: scroll;
            overflow-x: hidden;

            mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 17px, black 17px);
            mask-size: 100% 20000px;
            mask-position: left bottom;
            -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 17px, black 17px);
            -webkit-mask-size: 100% 20000px;
            -webkit-mask-position: left bottom;
            transition: mask-position 0.5s, -webkit-mask-position 0.3s;
            &:hover {
                -webkit-mask-position: left top;
            }

            .tasks {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px;
                list-style-type: none;
                li {
                    transition: all 0.15s ease-in !important;
                    padding: 10px 10px 10px 20px;
                    width: 100%;
                    &:hover {
                        background: $color_grey_1;
                        .dismiss-btn-container {
                            opacity: 1;
                        }
                    }
                }

                .task-name {
                    font-weight: bold;
                }

                .created-date {
                    font-size: 0.83em;
                    font-weight: 300;
                }

                .progress-bar {
                    width: 250px;
                    .p-progressbar-value {
                        background: $color_success;
                    }
                }

                .dismiss-btn-container {
                    opacity: 0;
                    position: relative;

                    .dismiss-btn {
                        svg {
                            transition: all 0.15s ease-in;
                            border-radius: 50%;
                            &:hover {
                                background-color: #b3b3b3;
                            }
                            cursor: pointer;
                        }
                        position: absolute;
                        top: -25px;
                        right: -55px;
                    }
                }

                .status-icon {
                    margin-right: 20px;
                    &.done {
                        color: $color_success;
                    }
                    &.fail {
                        color: red;
                    }
                }
            }
        }
    }
}
