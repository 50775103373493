@import "variables";
@import "variables-granteam";

.gt-notifications-container {
    position: relative;

    .triangle {
        position: absolute;
        min-width: 10px;
        min-height: 10px;
        left: -27px;
        top: 40px;
        z-index: 10001;
        background-color: $white;
        transform: translate(50%, -50%) rotate(45deg);
        border-left: 1px solid $color_separator;
        border-top: 1px solid $color_separator;
    }

    .gt-notifications {
        position: fixed;
        right: 20px;
        top: 70px;
        z-index: 10000;
        background-color: white;
        min-width: 200px;
        min-height: 200px;
        max-width: 350px;

        border: 1px solid $color_separator;
        box-shadow: $gt-box-shadow;
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        .notifications-list {
            max-height: calc(max(60vh, 300px));
            overflow: auto;
        }

        .notifications-header {
            font-size: 16px;
            font-weight: 700;
            padding: 20px 15px 20px 15px;
            border-bottom: 1px solid $color_separator;

            @include space-between-flex;
            gap: 20px;

            .mark-as-read-btn {
                font-weight: 700;
                font-size: 12px;
                color: $gt-purple-4;
                cursor: pointer;
            }
        }

        .notification {
            padding: 10px 15px 10px 25px;
            padding-left: 25px;
            cursor: pointer;
            color: $color_text_1;

            display: flex;
            flex-direction: column;
            position: relative;

            transition: all 0.2s ease-in;

            @include hover($color_grey_2);

            &:not(:last-of-type) {
                border-bottom: 1px solid $color_separator;
            }

            .notification-message-header {
                font-size: 14px;
                font-weight: 700;
                padding-bottom: 10px;
                color: $gt-purple-4;

                .title {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    line-clamp: 1;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }

                @include space-between-flex;

                .dismiss-notification {
                    width: 20px;
                    height: 20px;
                    transition: all 0.2s ease-in;
                    border-radius: 50%;

                    @include hover($color_grey_4);

                    @include center-flex;
                }
            }

            &.active::before {
                position: absolute;
                content: " ";
                left: 10px;
                transform: translateY(9px);
                border-radius: 50%;
                background-color: $gt-green-3;
                min-width: 8px;
                max-width: 8px;
                min-height: 8px;
                max-height: 8px;
            }

            .notification-content {
                font-size: 11px;
                color: $color_grey_6;
                padding-bottom: 5px;
                line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            time {
                font-weight: 700;
            }
        }

        .no-notifications {
            flex-grow: 1;
            width: 100%;
            color: $color_grey_5;
            flex-direction: column;
            @include center-flex;

            svg {
                width: 40px;
                height: 40px;
            }
        }
    }
}
