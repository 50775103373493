@import "variables";

.p-splitbutton{
    height: 29px;
    margin-top: 10px;
    margin-right: 10px;
}

.p-splitbutton-defaultbutton{
    text-transform: uppercase;
    color: $gt-green-3 !important;
    background: $color_text_3 !important;
    border-color: $gt-green-2 !important;
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 11px !important;
    font-weight: 600 !important;
}

.p-splitbutton-defaultbutton:hover{
    background: $gt-green-2 !important;
    color: $color_text_3 !important;
}

.gt-split-button .p-splitbutton-menubutton{
    background: $color_text_3 !important;
    color: $gt-green-2 !important;
}

.gt-split-button .p-splitbutton-menubutton:hover{
    background: $gt-green-2 !important;
    color: $color_text_3 !important;
}

.gt-split-button.main-disabled .p-splitbutton-defaultbutton{
  border-color: $gt-border-color !important;
  pointer-events: none;
  color: $gt-border-color !important;
}

.gt-split-button.dropdown-button-disabled .p-splitbutton-menubutton{
  border-color: $gt-border-color !important;
  pointer-events: none;
  color: $gt-border-color !important;
}
