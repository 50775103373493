.modal-header {
    .modal-title {
        font-family: "Open Sans", sans-serif !important;
    }

    .btn:focus {
        box-shadow: none;
    }

    .btn-hide-fields {
        transition: all 0.2s ease;
        &.rotate {
            transform: rotate(90deg);
        }
    }
}
