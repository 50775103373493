@import "variables";

$border: 1px solid $color_light_1 !default;

.color-input-placeholder {
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
}
#prime-color-input {
    display: flex;
    justify-content: center;
    .color-input-button {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: block;
        border: 1px solid $white;
        transition: all 0.2s ease-in;
        &:hover {
            opacity: 0.6;
        }
    }
}
.color-input-menu {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    animation: animateIn;
    animation-duration: 0.15s;
    &.align-cell {
        grid-template-columns: repeat(3, 1fr);
        padding: 6px;
        .toolbar-btn {
            border-radius: 5px;
        }
    }
    gap: 3px;
    background-color: white;
    color: $color_text_1;
    padding: 8px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    z-index: 1002;
    font-size: 1rem;
    font-weight: 400;

    .color-text-input {
        grid-area: 1 / 1 / 2 / 11;
        margin-bottom: 0px;
    }

    .context-menu-item {
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: $border;

        &:hover:not(.disabled) {
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        }
    }
}

@keyframes animateIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
