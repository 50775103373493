.error-page-fallback {
    $error-page-icon-size: 70px;
    $error-page-refresh-icon-size: 35px;
    svg {
        height: $error-page-icon-size;
        width: $error-page-icon-size;
    }
    h1 {
        margin-top: 2rem;
        font-weight: 600;
    }
    justify-content: center;
    align-items: center;

    .refresh-button {
        cursor: pointer;
        height: 40px;
        svg {
            height: $error-page-refresh-icon-size;
            width: $error-page-refresh-icon-size;
            transition: all 0.3s ease-in-out;
            &:hover {
                rotate: 270deg;
            }
        }
    }
}
