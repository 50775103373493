@import "variables";

.advanced-remark {
    overflow: visible;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .pi-flag {
        color: #ff8080;
    }

    span {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in;

        &:hover {
            background-color: $color_grey_4;
            color: $black;
        }
    }
}

.advanced-remark-modal .custom-modal-checkbox-container {
    margin-top: 16px;
}

.withdrawn {
    text-decoration: line-through;
}
