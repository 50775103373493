.hljs {
    color: $color_text_2;
}
.hljs-string {
    color: $color_danger;
}
.hljs-literal {
    color: $color_success;
}
.hljs-number {
    color: $color_primary;
}
