@import "variables";

.p-inputgroup {
    // height: 36px;
    & > button {
        border: none;
        margin: 0px !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        justify-content: center;
        align-items: center;
        background-color: $color_grey_5 !important;
        color: $color_text_2 !important;
        transition: 200ms opacity;
        &:hover {
            opacity: 0.8;
            background-color: $color_grey_5;
            border: none;
            color: $color_text_2;
        }
        &:focus:hover {
            background-color: $color_grey_5;
            color: $color_text_2;
        }
        &:active {
            background-color: $color_grey_5 !important;
            color: $color_text_2 !important;
        }
        &:focus {
            box-shadow: none !important;
        }

        &.disabled {
            background-color: $color_grey_5 !important;
            &:hover {
                opacity: 0.65;
            }
        }
    }

    .p-inputtext {
        border-color: $color_separator !important;
        padding: 9px 17px !important;
        &.is-invalid {
            background-color: #fef9fa;
            border-color: #fac6cc;
        }
        &:hover {
            border-color: $color_separator !important;
        }
        &:focus {
            box-shadow: $gt-box-shadow !important;
            border-color: $gt-border-color !important;
        }
    }

    .textarea-inputgroup {
        margin: 0px;
    }
}
