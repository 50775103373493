// navbar fix
.rui-navbar-show {
    .yaybar {
        margin-top: 60px;

        @include media-breakpoint-down($navbar_expand) {
            margin-top: 65px;
        }
    }
    @include media-breakpoint-down($navbar_expand) {
        &:not(.yay-hide) .yaybar {
            margin-top: 0;
        }
    }
}

.yaybar.yay-overlay.yay-hide-to-small ~ .content-wrap {
    margin-left: 70px;
}
.yaybar.yay-shrink ~ .content-wrap {
    will-change: margin-left;
    transition: margin-left .15s;
}

.yay-hide {
    .rui-yaybar.yaybar.yay-overlay {
        z-index: 1001;
    }
    .yaybar:hover,
    .yaybar.rui-yaybar.yay-overlay:hover {
        z-index: 10;
    }
    .yaybar.yay-hide-to-small .yaybar-wrap > ul > li:not(:hover) > .yay-submenu {
        // stylelint-disable-next-line
        display: none !important;
        visibility: hidden;
    }
    @include media-breakpoint-down(xs) {
        .yaybar {
            transform: translateX(-280px);
        }
    }
}
.yaybar.rui-yaybar {
    display: flex;
    flex-direction: column;
    overflow: initial;
    background-color: #fff;
    will-change: opacity, margin-top;
    transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-start;
    z-index: 1000;

    @include media-breakpoint-down(xs) {
        transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s step-start, z-index .15s step-start;
    }

    .yay-hide & {
        transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-end;
        z-index: 1000;

        @include media-breakpoint-down(xs) {
            transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s step-end, z-index .15s step-end;
        }

        &.yay-overlay {
            border-radius: 0;
            box-shadow: 0 0 0 5px #fff;

            + .rui-yaybar-bg {
                visibility: hidden;
                opacity: 0;
            }
            .rui-yaybar-brand {
                top: 5px;
                left: 5px;
                box-shadow: 0 0 0 5px #fff;
            }
        }

        .rui-yaybar-icons,
        .rui-yaybar-icons-substrate {
            display: none;
        }
        .rui-yaybar-logo {
            visibility: hidden;
            opacity: 0;
        }
        .rui-yaybar-brand {
            position: sticky;
            top: 0;
            right: 0;
            left: 0;
            background-color: #fff;
        }
        .yaybar-wrap > ul > li {
            > .yay-submenu {
                width: 250px;
                padding: 15px;
                margin: -15px 0 0 15px;
                // stylelint-disable-next-line
                background-color: #fff !important;
                border: 1px solid $color_grey_2;
                border-radius: 4px;
                box-shadow: 0 3px 10px rgba(0, 0, 0, .03);

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 22px;
                    height: 75px;
                    margin-left: -22px;
                }

                .dropdown-menu-triangle {
                    top: 30px;
                    right: auto;
                    bottom: auto;
                    left: 0;
                    margin: -5px 0 0 -19px;
                    border: 10px solid transparent;
                    border-right: 9px solid #fff;

                    &::before {
                        margin: -11px 0 0 -12px;
                        border: 11px solid transparent;
                        border-right: 10px solid $color_grey_2;
                    }
                }

                > li > a {
                    padding: 9px 14px 9px 30px;
                }
            }
            > a > .rui-yaybar-circle {
                top: 3px;
                left: -3px;
            }
        }
        // Right Side
        &.yay-right-side ul > li {
            > .yay-submenu {
                margin: -15px 15px 0 0;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: auto;
                    width: 22px;
                    height: 75px;
                    margin-right: -22px;
                    margin-left: 0;
                }

                .dropdown-menu-triangle {
                    top: 30px;
                    right: 0;
                    bottom: auto;
                    left: auto;
                    margin: -3px -19px 0 0;
                    border: 10px solid transparent;
                    border-left: 9px solid #fff;

                    &::before {
                        margin: -11px 0 2px -9px;
                        border: 11px solid transparent;
                        border-left: 10px solid $color_grey_2;
                    }
                }

                > li > a {
                    padding: 9px 14px 9px 30px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &:not(.yay-overlay) {
            opacity: 0;
        }
        &.yay-hide-to-small {
            &.yay-shrink ~ .content-wrap {
                margin-left: 70px;
            }
            &.yay-overlay ~ .content-wrap {
                margin-left: 70px;
            }
        }
    }

    .yay-wrap-menu {
        flex: 1;
    }

    + .rui-yaybar-bg {
        // Bg
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        visibility: hidden;
        background-color: rgba($color_dark_2, .9);
        opacity: 0;
        transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
        z-index: 1003;
    }

    // Fix Dropdown menu
    .dropdown-menu {
        padding: 16px 15px;

        .nav-link {
            padding: 9px 15px;

            > .rui-nav-circle {
                left: 5px;
            }
        }
    }

    // Bottom Icons
    .rui-yaybar-icons {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        min-height: 70px;
        padding: 0 15px;
        z-index: 2;
    }

    // Brand Block
    .rui-yaybar-brand {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 70px;
        padding: 0 30px;
        z-index: 2;
    }

    &.yay-overlay {
        top: 5px;
        bottom: 5px;
        left: 5px;
        border-radius: .25rem;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .03);
        z-index: 1004;

        + .rui-yaybar-bg {
            visibility: visible;
            opacity: 1;
        }
        .rui-yaybar-brand {
            min-height: 60px;
        }
        .rui-yaybar-icons {
            min-height: 60px;
        }
    }

    // Scrollbar
    .os-scrollbar {
        padding-right: 0;
        background-color: #fff;

        > .os-scrollbar-track > .os-scrollbar-handle {
            background-color: $color_grey_2;
        }
        &:hover > .os-scrollbar-track > .os-scrollbar-handle,
        &.active > .os-scrollbar-track > .os-scrollbar-handle {
            background-color: $color_grey_4;
        }
        > .os-scrollbar-track {
            background-color: #fff;
        }
        &.os-scrollbar-vertical {
            width: 6px;
            margin-right: 1px;
        }
        &.os-scrollbar-horizontal {
            height: 6px;
        }
    }
    &.os-host-scrolling .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
        background-color: $color_grey_3;
    }

    // Logo
    .rui-yaybar-logo {
        visibility: visible;
        opacity: 1;
        transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    }

    .yaybar-wrap {
        > ul {
            padding: 18px 15px 15px;
        }

        // Dropdown First
        ul {
            > li {
                > .yay-submenu {
                    // stylelint-disable-next-line
                    background-color: transparent !important;
                }
                > a {
                    position: relative;
                    padding: 9px 15px;
                    color: $color_grey_6;
                    // stylelint-disable-next-line
                    background-color: transparent !important;
                    transition: background-color .3s, font-size 0s, .1s color;

                    > span,
                    > svg {
                        z-index: 1;
                    }

                    &::before {
                        background-color: $color_grey_5;
                    }
                    > span:not([class]) {
                        color: $color_text_1;
                    }
                    > .rui-yaybar-circle {
                        position: absolute;
                        left: 5px;
                        width: 36px;
                        height: 36px;
                        background-color: $color_grey_1;
                        border-radius: 100%;
                        opacity: 0;
                        transition: opacity .1s ease-in-out;
                        z-index: 0;
                    }
                    > .yay-icon {
                        margin-right: 14px;
                        color: $color_grey_5;
                        transition: .1s color;
                    }
                    > .yay-icon-collapse {
                        color: $color_grey_5;
                        transition: transform .15s ease-in-out, color .1s ease-in-out;
                    }
                }
                &:hover > a,
                &.hover > a {
                    color: $color_text_1;

                    &::before {
                        background-color: $color_text_1;
                    }
                    > span:not([class]) {
                        color: $color_text_1;
                    }
                    > .rui-yaybar-circle {
                        opacity: 1;
                    }
                    > .yay-icon {
                        color: $color_text_1;
                    }
                    > .yay-icon-collapse {
                        color: $color_grey_5;
                    }
                }
                &.yay-label {
                    display: block;
                    height: auto;
                    padding: 11px 15px;
                    font-size: 12px;
                    line-height: normal;
                    color: $color_grey_5;
                    text-transform: none;
                    opacity: 1;
                }
                + .yay-label {
                    margin-top: 11px;
                }
                &.yay-item-active > a {
                    // stylelint-disable-next-line
                    color: $color_brand !important;

                    &::before {
                        background-color: $color_brand;
                    }
                    > span:not([class]) {
                        color: $color_brand;
                    }
                    > .rui-yaybar-circle {
                        opacity: 0;
                    }
                    > .yay-icon {
                        color: $color_brand;
                    }
                    > .yay-icon-collapse {
                        color: $color_brand;
                    }
                }
                &.yay-item-active.yay-submenu-open > a {
                    // stylelint-disable-next-line
                    color: $color_brand !important;

                    &::before {
                        background-color: $color_brand;
                    }
                    > span:not([class]) {
                        color: $color_brand;
                    }
                    > .rui-yaybar-circle {
                        opacity: 0;
                    }
                    > .yay-icon {
                        color: $color_brand;
                    }
                    > .yay-icon-collapse {
                        color: $color_brand;
                    }
                }
                &.yay-submenu-open > a {
                    // stylelint-disable-next-line
                    color: $color_text_1 !important;

                    &::before {
                        background-color: $color_text_1;
                    }
                    > span:not([class]) {
                        color: $color_text_1;
                    }
                    > .rui-yaybar-circle {
                        opacity: 0;
                    }
                    > .yay-icon {
                        color: $color_text_1;
                    }
                    > .yay-icon-collapse {
                        color: $color_text_1;
                    }
                }
            }

            // Dropdown Child
            .yay-submenu {
                > li {
                    > a {
                        padding-left: 45px;
                        color: $color_grey_6;

                        > span:not([class]) {
                            color: inherit;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            display: inline-block;
                            top: 50%;
                            width: 2px;
                            height: 2px;
                            margin-top: -1px;
                            margin-left: -16px;
                            border-radius: 2px;
                            transition: background-color .15s ease-in-out;
                        }
                    }
                }

                // Dropdown Last
                .yay-submenu > li {
                    > a {
                        padding-left: 60px;
                    }
                    &.yay-submenu-open > a {
                        color: $color_text_1;
                    }
                }
            }
        }
    }
}

// Dark
.yay-hide .rui-yaybar-dark.rui-yaybar {
    &.yay-overlay {
        box-shadow: 0 0 0 5px $color_dark_1;

        .rui-yaybar-brand {
            box-shadow: 0 0 0 5px $color_dark_1;
        }
    }
    ~ .rui-navbar-top.rui-navbar-dark {
        box-shadow: -5px 0 0 $color_dark_1;
    }
    .rui-yaybar-brand {
        background-color: $color_dark_1;
    }
    .yaybar-wrap > ul > li {
        > .yay-submenu {
            // stylelint-disable-next-line
            background-color: $color_dropdown_bg !important;
            border-color: $color_dropdown_border;
            box-shadow: 0 3px 10px $color_dropdown_shadow;

            .dropdown-menu-triangle {
                border-color: transparent;
                border-right-color: $color_dropdown_bg;

                &::before {
                    border-color: transparent;
                    border-right-color: $color_dropdown_border;
                }
            }
        }
    }
    // Right Side
    &.yay-right-side ul > li {
        > .yay-submenu {
            .dropdown-menu-triangle {
                border-color: transparent;
                border-left-color: $color_dropdown_bg;

                &::before {
                    border-color: transparent;
                    border-left-color: $color_dropdown_border;
                }
            }
        }
    }
}
.rui-yaybar-dark.rui-yaybar {
    background-color: $color_dark_1;

    .yaybar-wrap {
        background-color: $color_dark_1;
    }
    + .rui-yaybar-bg {
        background-color: rgba($color_dark_4, .85);
    }

    // Scrollbar
    .os-scrollbar {
        background-color: $color_dark_1;

        > .os-scrollbar-track > .os-scrollbar-handle {
            background-color: $color_dark_3;
        }
        &:hover > .os-scrollbar-track > .os-scrollbar-handle,
        &.active > .os-scrollbar-track > .os-scrollbar-handle {
            background-color: $color_dark_5;
        }
        > .os-scrollbar-track {
            background-color: $color_dark_1;
        }
    }
    &.os-host-scrolling .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
        background-color: $color_dark_4;
    }
    &.yay-overlay {
        box-shadow: 0 3px 10px $color_dropdown_shadow;
    }

    // Icons
    .rui-yaybar-icons {
        .btn-custom-round {
            color: $color_dark_7;

            > .rui-icon {
                stroke: $color_dark_7;
            }
            &:hover::after,
            &.hover::after {
                background-color: $color_dark_2;
            }
        }
    }

    // Nav
    .nav {
        .nav .nav-link {
            &::before {
                background-color: $color_dark_5;
            }
            &:hover::before,
            &.hover::before,
            &:focus::before,
            &.focus::before,
            &.active::before {
                background-color: $color_light_1;
            }
        }
        .nav-link {
            color: $color_dark_7;

            > span:not([class]) {
                color: $color_light_1;
            }
            > .rui-icon {
                stroke: $color_dark_6;
            }
            > .badge,
            > .badge-circle {
                box-shadow: 0 0 0 2px $color_dark_1;
            }

            &.show {
                color: $color_dark_6;

                > span:not([class]) {
                    color: $color_light_1;
                }
                > .rui-icon {
                    stroke: $color_dark_5;
                }
            }
            &:hover,
            &.hover,
            &:focus,
            &.focus {
                color: $color_light_1;

                > span:not([class]) {
                    color: $color_light_1;
                }
                > .rui-icon {
                    stroke: $color_light_1;
                }
            }
            &.active {
                color: $color_light_1;

                > span:not([class]) {
                    color: $color_brand;
                }
                > .rui-icon {
                    stroke: $color_brand;
                }
            }
            &.disabled {
                color: $color_dark_4;

                > span:not([class]) {
                    color: $color_dark_4;
                }
                > .rui-icon {
                    stroke: $color_dark_4;
                }
            }
        }
    }

    .yaybar-wrap ul {
        > li {
            > a {
                color: $color_dark_6;

                &::before {
                    background-color: $color_dark_6;
                }
                > span:not([class]) {
                    color: $color_light_1;
                }
                > .rui-yaybar-circle {
                    background-color: $color_dark_2;
                }
                .yay-icon {
                    color: $color_dark_7;
                }
                .yay-icon-collapse {
                    color: $color_dark_6;
                }
            }
            &:hover > a,
            &.hover > a {
                color: $color_light_1;

                &::before {
                    background-color: $color_light_1;
                }
                > span:not([class]) {
                    color: $color_light_1;
                }
                > .yay-icon {
                    color: $color_light_1;
                }
                > .yay-icon-collapse {
                    color: $color_light_1;
                }
            }
            &.yay-label {
                color: $color_dark_7;
            }
            &.yay-item-active > a {
                // stylelint-disable-next-line
                color: $color_brand !important;

                &::before {
                    background-color: $color_brand;
                }
                > span:not([class]) {
                    color: $color_brand;
                }
                > .yay-icon {
                    color: $color_brand;
                }
                > .yay-icon-collapse {
                    color: $color_brand;
                }
            }
            &.yay-item-active.yay-submenu-open > a {
                color: $color_brand;

                &::before {
                    background-color: $color_brand;
                }
                > span:not([class]) {
                    color: $color_brand;
                }
                > .yay-icon {
                    color: $color_brand;
                }
                > .yay-icon-collapse {
                    color: $color_brand;
                }
            }
            &.yay-submenu-open > a {
                // stylelint-disable-next-line
                color: $color_light_1 !important;

                &::before {
                    background-color: $color_light_1;
                }
                > span:not([class]) {
                    color: $color_light_1;
                }
                > .yay-icon {
                    color: $color_light_1;
                }
                > .yay-icon-collapse {
                    color: $color_light_1;
                }
            }
        }

        // Dropdown Child
        .yay-submenu > li {
            > a {
                color: $color_dark_7;
            }
            &:hover > a,
            &.hover > a {
                color: $color_light_1;

                > span:not([class]) {
                    color: $color_light_1;
                }
            }
        }
        &.yay-submenu-open > a {
            color: $color_light_1;
        }
    }

    // Dropdown menu
    .dropdown .dropdown-item {
        color: $color_dark_7;

        > span:not([class]) {
            color: $color_light_1;
        }
        > .rui-icon {
            stroke: $color_dark_6;
        }
        &:hover,
        &.hover {
            color: $color_light_1;

            > span:not([class]) {
                color: $color_light_1;
            }
            > .rui-icon {
                stroke: $color_light_1;
            }
        }
    }
    .dropdown:not(.dropdown-hover) {
        &:focus,
        &.focus {
            color: $color_light_1;

            > span:not([class]) {
                color: $color_light_1;
            }
            > .rui-icon {
                stroke: $color_light_1;
            }
        }
        &:active {
            color: $color_brand;

            > span:not([class]) {
                color: $color_brand;
            }
            > .rui-icon {
                stroke: $color_brand;
            }
        }
        &.show > .dropdown-item {
            color: $color_light_1;
            background-color: transparent;

            > span:not([class]) {
                color: $color_light_1;
            }
            > .rui-icon {
                stroke: $color_light_1;
            }
        }
    }

    // Hover
    .dropdown-hover.hover > .dropdown-item {
        color: $color_light_1;

        > span:not([class]) {
            color: $color_light_1;
        }
        > .rui-icon {
            stroke: $color_light_1;
        }
        &.btn-custom-round,
        > .btn-custom-round {
            color: $color_secondary;

            &::after {
                background-color: $color_dark_2;
            }
        }
    }

    // Menu
    .dropdown-menu {
        background-color: $color_dropdown_bg;
        border-color: $color_dropdown_border;
        box-shadow: 0 3px 10px $color_dropdown_shadow;

        .rui-nav-circle,
        .rui-dropdown-circle {
            background-color: $color_dark_2;
        }
    }

    // Dropdown
    .dropdown-triangle .dropdown-menu[x-placement="bottom-start"],
    .dropdown-triangle .dropdown-menu[x-placement="bottom-end"],
    .dropdown-triangle .dropdown-menu {
        > .dropdown-menu-triangle {
            border-color: transparent;
            border-bottom-color: $color_dropdown_bg;

            &::before {
                border-color: transparent;
                border-bottom-color: $color_dropdown_border;
            }
        }
    }

    // Dropup
    .dropdown-triangle .dropdown-menu[x-placement="top-start"],
    .dropdown-triangle .dropdown-menu[x-placement="top-end"] {
        > .dropdown-menu-triangle {
            border-color: transparent;
            border-top-color: $color_dropdown_bg;

            &::before {
                border-color: transparent;
                border-top-color: $color_dropdown_border;
            }
        }
    }

    // Dropright and Dropleft
    .dropdown-triangle .dropdown-menu {
        // Dropright
        &[x-placement="right-start"] > .dropdown-menu-triangle {
            border-color: transparent;
            border-right-color: $color_dropdown_bg;

            &::before {
                border-color: transparent;
                border-right-color: $color_dropdown_border;
            }
        }
        &[x-placement="right-end"] > .dropdown-menu-triangle {
            border-color: transparent;
            border-right-color: $color_dropdown_bg;

            &::before {
                border-color: transparent;
                border-right-color: $color_dropdown_border;
            }
        }

        // Dropleft
        &[x-placement="left-start"] > .dropdown-menu-triangle {
            border-color: transparent;
            border-left-color: $color_dropdown_bg;

            &::before {
                border-color: transparent;
                border-left-color: $color_dropdown_border;
            }
        }
        &[x-placement="left-end"] > .dropdown-menu-triangle {
            border-color: transparent;
            border-left-color: $color_dropdown_bg;

            &::before {
                border-color: transparent;
                border-left-color: $color_dropdown_border;
            }
        }
    }
}
