@import "variables";
@import "variables-granteam";

.role-filters-btn-container {
    display: flex;
    .dummy {
        width: 100%;
    }

    .role-filters-btn {
        color: $color_brand;
        gap: 5px;
        cursor: pointer;
        font-size: 14px;
        @include center-flex;
        justify-content: flex-end;
        user-select: none;
        text-wrap: nowrap;

        &:hover {
            svg {
                scale: 1.2;
            }
        }

        svg {
            transition: all 0.2s ease-in-out;
            height: 20px;
            width: 20px;
        }

        &.disabled {
            color: $color_secondary;
            pointer-events: none;
        }
    }
}

.config-block {
    border-top: 1px solid $color_separator;
    border-bottom: 1px solid $color_separator;
    margin: 20px 0px;
    padding: 20px 0px;
}

.filters-list {
    list-style: none;
    padding-left: 30px;

    li:not(:last-child) {
        margin-bottom: 5px;
    }

    li {
        display: flex;
        gap: 5px;

        .filter-block {
            padding: 5px;
            border: 1px solid $color_separator;
            border-radius: 5px;
        }
    }
}

.applied-filters-label {
    margin-bottom: 20px;
    font-size: 14px;
}

.role-title {
    margin-bottom: 5px;
}
