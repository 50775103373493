.spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

.col:has(.spinner-container),
.col:has(.prime-table-container) {
    min-height: 300px;
}
