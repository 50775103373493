.emojionearea.rui-messenger-textarea {
    .emojionearea-editor {
        color: $color_text_1;

        &::before {
            color: $color_dark_5;
        }
    }

    // correct emoji position
    > .emojionearea-button {
        color: $color_dark_5;

        &:hover {
            color: inherit;
        }
    }

    // Picker
    .emojionearea-picker {
        background-color: $color_dropdown_bg;
        border-color: $color_dropdown_border;
        box-shadow: 0 3px 10px $color_dropdown_shadow;

        &.rui-messenger-picker-complete .rui-messenger-picker-triangle {
            border-color: transparent;
            border-top-color: $color_dropdown_bg;

            &::before {
                border-color: transparent;
                border-top-color: $color_dropdown_border;
            }
        }
        .emojionearea-scroll-area {
            &::-webkit-scrollbar-track {
                background-color: $color_dropdown_bg;
            }
            &::-webkit-scrollbar {
                background-color: $color_dropdown_bg;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $color_dark_3;
            }
        }
        .emojibtn {
            background-color: $color_dropdown_bg;

            &:hover {
                background-color: $color_dark_3;
            }
        }
        .emojionearea-filters {
            background-color: $color_dropdown_bg;

            .emojionearea-filter.active {
                background-color: $color_dropdown_bg;
            }
        }
        .emojionearea-category-title {
            color: $color_dark_5;
            background-color: $color_dropdown_bg;
        }
    }
}

// Form-control
.emojionearea,
.emojionearea.form-control {
    background-color: $color_dark_1;
}

// Autocomplete dropdown
.textcomplete-dropdown.dropdown-menu[data-strategy="emojionearea"] {
    border-color: $color_dark_3;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .03);

    li.textcomplete-item {
        &.active,
        &:hover {
            background-color: $color_dark_3;
        }
    }
}
