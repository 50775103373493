@import "variables";

.btn.mobile {
    background-color: $gt-green-2;
    color: $white;
    border-radius: 24px;
    &:not(:hover):not(:active:focus) {
        background-color: $gt-green-2;
    }
}
