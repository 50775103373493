@import "variables";

.custom-modal-select {
    &:focus-within {
        z-index: 2000 !important;
    }

    & > div:nth-of-type(1) {
        border-color: $color_separator;
        transition: border-color 0.2s ease-in-out;
        box-shadow: none !important;

        &:hover {
            border-color: $gt-border-color;
        }

        &:focus-within {
            border-color: rgba($gt-green-2, 0.75) !important;
            box-shadow: 0 0 0 0.2rem rgba($gt-green-2, 0.2) !important;
        }
    }

    & > div:nth-of-type(2) {
        & > * {
            & > * {
                &:active {
                    background-color: rgba($gt-green-2, 0.2) !important;
                }

                &:hover {
                    background-color: rgba($gt-green-2, 0.2) !important;
                }
            }
        }
    }
}

.invalid-feedback {
    display: block !important;
}

.custom-select-disabled {
    // opacity: 0;
}

.deleteable-option {
    &:hover {
        background-color: #2fc78740;
    }

    &.selected-opt {
        background-color: #725ec3;
        color: white;
    }

    padding-top: 3px;
    padding-bottom: 3px;

    padding-left: 10px;
}

.textarea {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    height: 100px !important;
    resize: none !important;

    &.p-inputtext:focus {
        box-shadow: 0 0 0 0 transparent !important;
    }

    &:focus {
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
    }
}
