// stylelint-disable declaration-no-important

// Rounded
.rounded {
    border-radius: 0.25rem !important;
}

// Border
.border {
    border-color: $color_grey_2 !important;
}

/*
 * Text Color Helper
 */
@each $name, $color in $colors_main {
    .text-#{$name} {
        color: $color !important;
    }
}

/*
 * Bg Color Helper
 */
@each $name, $color in $colors_main {
    .bg-#{$name} {
        background-color: $color !important;
    }
}

/*
 * Image Fit
 */
.rui-img {
    max-width: 100%;
    height: auto;

    img {
        max-width: 100%;
        height: auto;
    }
}

/*
 * Text Align Helper
 */
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}

/*
 * Inline Block Helper
 */
.dib {
    display: inline-block !important;
}

/*
 * Block Helper
 */
.db {
    display: block !important;
    width: 100%;
}

/*
 * Text Transform Helper
 */
.text-uppercase {
    text-transform: uppercase !important;
}

/*
 * White-space Helper
 */
.wsw {
    white-space: normal !important;
}
.wsnw {
    white-space: nowrap !important;
}
.wsp {
    white-space: pre !important;
}

/*
 * Width Helper
 */
$helper-w: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100 !default;
$helper-wpx: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 150, 200, 250, 300, 350, 400, 450, 500 !default;
@each $item in $helper-w {
    .w-#{$item} {
        width: #{$item}% !important;
    }
}
@each $item in $helper-wpx {
    .wpx-#{$item} {
        width: #{$item}px !important;
    }
}

/*
 * height Helper
 */
$helper-h: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100 !default;
$helper-hpx: 5, 10, 15, 20, 25, 30, 35, 36, 40, 45, 50, 75, 100, 150, 200, 250, 300, 350, 400, 450, 500 !default;
@each $item in $helper-h {
    .h-#{$item} {
        height: #{$item}% !important;
    }
}
@each $item in $helper-h {
    .vh-#{$item} {
        height: #{$item}vh !important;
    }
}
@each $item in $helper-hpx {
    .hpx-#{$item} {
        height: #{$item}px !important;
    }
}

/*
 * Font Weight Helper
 */
$helper-fw: 100, 200, 300, 400, 500, 600, 700, 800, 900 !default;
@each $item in $helper-fw {
    .fw-#{$item} {
        font-weight: #{$item} !important;
    }
}

/*
 * Font Size Helper
 */
$helper-fs: 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 30, 35, 40, 45, 50 !default;
@each $item in $helper-fs {
    .fs-#{$item} {
        font-size: #{$item}px !important;
    }
}

/*
 * Line Height Helper
 */
$helper-lh: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50 !default;
@each $item in $helper-lh {
    .lh-#{$item} {
        line-height: #{$item}px !important;
    }
}

/*
 * Border Radius Helper
 */
$helper-br: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 25, 30, 35 !default;
@each $item in $helper-br {
    .br-#{$item} {
        border-radius: #{$item}px !important;
    }
}

/*
 * Paddings Helper
 */
$helper-p: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 85, 90, 100 !default;
@each $item in $helper-p {
    .p-#{$item} {
        padding: #{$item}px !important;
    }
}
@each $item in $helper-p {
    .pt-#{$item} {
        padding-top: #{$item}px !important;
    }
}
@each $item in $helper-p {
    .pl-#{$item} {
        padding-left: #{$item}px !important;
    }
}
@each $item in $helper-p {
    .pr-#{$item} {
        padding-right: #{$item}px !important;
    }
}
@each $item in $helper-p {
    .pb-#{$item} {
        padding-bottom: #{$item}px !important;
    }
}
@each $item in $helper-p {
    .py-#{$item} {
        padding-top: #{$item}px !important;
        padding-bottom: #{$item}px !important;
    }
}
@each $item in $helper-p {
    .px-#{$item} {
        padding-right: #{$item}px !important;
        padding-left: #{$item}px !important;
    }
}

/*
 * Margins Helper
 */
$helper-m: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 15, 18, 20, 21, 22, 23, 24, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90, 100 !default;
@each $item in $helper-m {
    .m-#{$item} {
        margin: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .mt-#{$item} {
        margin-top: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .ml-#{$item} {
        margin-left: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .mr-#{$item} {
        margin-right: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .mb-#{$item} {
        margin-bottom: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .my-#{$item} {
        margin-top: #{$item}px !important;
        margin-bottom: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .mx-#{$item} {
        margin-right: #{$item}px !important;
        margin-left: #{$item}px !important;
    }
}
/*
 * Negative Margins Helper
 */
@each $item in $helper-m {
    .mn-#{$item} {
        margin: #{$item * -1}px !important;
    }
}
@each $item in $helper-m {
    .mnt-#{$item} {
        margin-top: #{$item * -1}px !important;
    }
}
@each $item in $helper-m {
    .mnl-#{$item} {
        margin-left: #{$item * -1}px !important;
    }
}
@each $item in $helper-m {
    .mnr-#{$item} {
        margin-right: #{$item * -1}px !important;
    }
}
@each $item in $helper-m {
    .mnb-#{$item} {
        margin-bottom: #{$item * -1}px !important;
    }
}
@each $item in $helper-m {
    .mny-#{$item} {
        margin-top: #{$item * -1}px !important;
        margin-bottom: #{$item * -1}px !important;
    }
}
@each $item in $helper-m {
    .mnx-#{$item} {
        margin-right: #{$item * -1}px !important;
        margin-left: #{$item * -1}px !important;
    }
}

/*
 * margin rem helper
 */

$helper-m-rem: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20 !default;
@each $item in $helper-m-rem {
    .mrem-#{$item} {
        margin: #{$item}rem !important;
    }
}
@each $item in $helper-m-rem {
    .mtrem-#{$item} {
        margin-top: #{$item}rem !important;
    }
}
@each $item in $helper-m-rem {
    .mlrem-#{$item} {
        margin-left: #{$item}rem !important;
    }
}
@each $item in $helper-m-rem {
    .mrrem-#{$item} {
        margin-right: #{$item}rem !important;
    }
}
@each $item in $helper-m-rem {
    .mbrem-#{$item} {
        margin-bottom: #{$item}rem !important;
    }
}
@each $item in $helper-m-rem {
    .myrem-#{$item} {
        margin-top: #{$item}rem !important;
        margin-bottom: #{$item}rem !important;
    }
}
@each $item in $helper-m-rem {
    .mxrem-#{$item} {
        margin-right: #{$item}rem !important;
        margin-left: #{$item}rem !important;
    }
}

/*
 * Opacity
 */
$helper-op: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 !default;
@each $item in $helper-op {
    .op-#{$item} {
        opacity: #{$item / 10} !important;
    }
}

/*
* Wide modal class 
*/
$helper-wide: 60, 65, 70, 75, 80, 85, 90, 95 !default;
@each $item in $helper-wide {
    .wide-modal-container-#{$item} {
        min-width: #{$item}vw !important;
        width: 100%;

        .inputs-container {
            .form-group {
                margin-bottom: 0 !important;
            }
        }
    }
}

/*
 * modal height Helper
 */
$helper-high: 45, 50, 55, 60, 65, 68, 70, 75, 80, 85, 90, 95 !default;
@each $item in $helper-high {
    .modal-container-height-#{$item} {
        height: #{$item}vh !important;

        .modal-content {
            height: #{$item}vh !important;

            .modal-body {
                height: calc(#{$item}vh - 150px) !important;
            }
        }
    }
}

.textarea-container {
    textarea {
        height: 150px !important;
    }
}

.modal-custom-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
}

.table-button-container-with-select {
    .input-row.prime-select.row {
        display: inline-flex;
        width: 200px !important;
        height: 100%;
        margin-right: 10px !important;
        justify-content: center;
        align-items: center;
        padding-top: 10px;

        div {
            margin-top: unset !important;
            padding-right: 0 !important;
            height: 28px !important;

            .p-dropdown {
                height: 28px !important;

                .p-dropdown-label {
                    height: 28px !important;
                    padding: 2px 20px 0 10px;
                }
            }
        }
    }
}
