.nav {
    .nav .nav-link {
        &::before {
            background-color: $color_dark_5;
        }
        &:hover::before,
        &.hover::before,
        &:focus::before,
        &.focus::before,
        &.active::before {
            background-color: $color_text_1;
        }
    }
    .nav-link {
        color: $color_dark_7;

        > span:not([class]) {
            color: $color_text_1;
        }
        > .rui-nav-circle {
            background-color: $color_dark_2;
        }
        > .rui-icon {
            stroke: $color_dark_6;
        }
        > .badge,
        > .badge-circle {
            box-shadow: 0 0 0 2px $color_dark_1;
        }

        &.show {
            color: $color_dark_6;

            > span:not([class]) {
                color: $color_text_1;
            }
            > .rui-icon {
                stroke: $color_dark_5;
            }
        }
        &:hover,
        &.hover,
        &:focus,
        &.focus {
            color: $color_text_1;

            > span:not([class]) {
                color: $color_text_1;
            }
            > .rui-icon {
                stroke: $color_text_1;
            }
        }
        &.active {
            color: $color_text_1;

            > span:not([class]) {
                color: $color_brand;
            }
            > .rui-icon {
                stroke: $color_brand;
            }
        }
        &.disabled {
            color: $color_dark_4;

            > span:not([class]) {
                color: $color_dark_4;
            }
            > .rui-icon {
                stroke: $color_dark_4;
            }
        }
    }
}
// Tabs
.nav-tabs {
    border-color: $color_dark_3;

    .nav-link {
        &.show {
            color: $color_dark_6;
        }
        &:hover,
        &.hover,
        &:focus,
        &.focus {
            color: $color_text_1;
        }
        &.active {
            color: $color_brand;
            border-bottom-color: rgba($color_brand, 0.8);
        }
    }
}
// Pills
.nav-pills {
    background-color: $color_dark_3;

    .nav-link {
        color: $color_dark_7;

        &.show {
            color: $color_dark_7;
        }
        &:hover,
        &.hover,
        &:focus,
        &.focus {
            color: $color_secondary;
        }
        &.active {
            color: $color_secondary;
            background-color: $color_dark_1;
        }
        &.disabled {
            color: $color_dark_5;
        }
    }
}

// GT Pills
.gt-nav-pills {
    .nav-link {
        color: $color_dark_7;

        &.show {
            color: $color_dark_7;
        }
        &:hover,
        &.hover,
        &:focus,
        &.focus {
            color: $color_secondary;
        }
        &.active {
            color: $color_secondary;
            background-color: $color_dark_1;
        }
        &.disabled {
            color: $color_dark_5;
        }
    }
}
