@import "variables";

input {
    text-overflow: ellipsis;
}

.form-control {
    background-color: #fff;
}

.modal-input {
    .form-control {
        border-color: $color_separator;

        &:hover:not(:disabled),
        &:hover:not(:readonly),
        &:focus {
            border-color: $gt-border-color;
            background-color: $white;
        }
    }
}
