@import "variables";

$default-marker-size: 32px;
$hover-marker-size: 40px;

.gt-marker {
    svg {
        position: absolute;
        height: $default-marker-size;
        width: $default-marker-size;
        left: -$default-marker-size / 2;
        top: -$default-marker-size;
        color: $color_brand;
        opacity: 0.9;
        transition: all 0.2s ease-in-out;

        &:hover {
            height: $hover-marker-size;
            width: $hover-marker-size;
            left: -$hover-marker-size / 2;
            top: -$hover-marker-size;
        }
    }

    &.hover {
        .pin-index {
            z-index: 10;
            height: $hover-marker-size;
            width: $hover-marker-size;
            left: -$hover-marker-size / 2;
            top: -$hover-marker-size;
            font-size: 1.2rem;
        }
        svg {
            z-index: 10;
            height: $hover-marker-size;
            width: $hover-marker-size;
            left: -$hover-marker-size / 2;
            top: -$hover-marker-size;
        }
    }

    .pin-index {
        position: relative;
        transition: all 0.2s ease-in-out;
        height: $default-marker-size;
        width: $default-marker-size;
        left: -$default-marker-size / 2;
        top: -$default-marker-size;
        padding-bottom: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $white;
        font-size: 1.1rem;
    }
}

.gt-marker-tooltip {
    .tooltip {
        top: -$hover-marker-size !important;
    }
}
