.custom-state-input-container {
    position: relative;
    margin: 0 !important;
    width: 100%;
}

.state-input-small-size {
    min-height: 0 !important;
    height: 24px !important;
}

.state-input-filter-settings-button,
.state-input-clear-button-small,
.state-input-clear-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: absolute;
    top: 6px;
    right: 0.25rem;
    width: 23px;
    height: 23px;
    border-radius: 50px;
    transition: 0.2s;
    color: #777;
    background-color: white;

    &:hover {
        background-color: #efefef;
        color: #777;
    }

    &:active {
        background-color: #e6e6e6;
        color: #4d4d4d;
        box-shadow: 0 0 0 0.2rem #cdeedf;
    }
}

.state-input-filter-settings-button {
    right: 2rem;
}

.state-input-clear-button.small {
    top: 3px;
    right: 3%;
    width: 19px;
    height: 19px;
}

.state-input-clear-button.as-second-icon { 
    right: 2rem; 
}
