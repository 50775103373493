.rui-section-lines {
    // Page
    .rui-page-title {
        border-bottom-color: $color_dark_3;
    }

    // Footer
    .rui-footer {
        border-top-color: $color_dark_3;
    }

    // Navbar
    .rui-navbar-top {
        border-bottom-color: $color_dark_3;
    }
    .rui-navbar-mobile {
        box-shadow: -5px 5px 0 0 $color_dark_1, 5px 5px 0 0 $color_dark_1, -5px -5px 0 0 $color_dark_1, 5px -5px 0 0 $color_dark_1, -5px 6px 0 $color_dark_3, 5px 6px 0 $color_dark_3;
    }

    // Yaybar
    &.yay-hide .yaybar.rui-yaybar.yay-overlay {
        box-shadow: 5px 0 0 $color_dark_1, -5px 0 0 $color_dark_1, 6px 0 0 $color_dark_3;
    }
    .rui-yaybar {
        box-shadow: 1px 0 0 $color_dark_3;
    }
    .rui-yaybar-icons {
        box-shadow: 0 -1px 0 $color_dark_3;
    }
}
