.rui-page-content {
    min-height: calc(100vh - 107px);
}

.table-grid-layout {
    display: grid !important;
    grid-auto-flow: row;
    gap: 10px;
    grid-auto-rows: minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
    height: 100%;
}

.gt-table {
    height: 100%;
}
