@import "variables";

$tooltip-background-color: $color_dark_1;
$tooltip-transition-time: 0.2s;

.tooltip {
    z-index: 10001;

    .tooltip-inner {
        font-size: 14px;
        background-color: $tooltip-background-color;
    }

    .arrow::before {
        border-top-color: $tooltip-background-color !important;
    }
}

.gt-tooltip {
    z-index: 10001;

    .gt-popper-tooltip {
        transition: opacity $tooltip-transition-time ease-in;

        .tooltip-inner {
            font-size: 14px;
            background-color: $tooltip-background-color;
        }

        .arrow::before {
            border-top-color: $tooltip-background-color !important;
            transition: border-color $tooltip-transition-time ease-in;
        }
    }
}
