@import "variables";
.add-from-lookup--container {
    position: relative;
    .select-container {
        label {
            margin-right: 2.2rem;
        }
    }

    .modal-textarea {
        width: 100% !important;
        border-radius: 3px;
        padding: 0.5rem;
        outline: none;
        border-color: #eaecf0;
    }
    .p-dropdown {
        width: 100%;
        margin-bottom: 10px;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;

        button {
            margin-top: 0 !important;
        }

        button:last-of-type {
            margin-right: 0 !important;
        }
    }
    &.already-exist {
        .error-message {
            position: absolute;
            top: 158px;
            left: 40px;
            color: red;
        }
        .modal-textarea {
            color: red;
            border-color: red;
        }
    }

    .modal-textarea {
        height: 250px;
    }
}
