.modal .close {
    color: $color_dark_6;

    &:hover,
    &.hover {
        color: $color_text_1;
    }
}
.modal-content {
    background-color: $color_dark_1;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .1);
}
// Backdrop
.modal-backdrop {
    background-color: rgba($color_dark_4, .85);
}
