@import "variables";
$tabs-color-disabled: repeating-linear-gradient(-45deg, #22222205, #22222205 10px, #33333310 10px, #33333310 20px);

.tabs-container {
    height: 100%;
}

.tabs-wrapper {
    height: 100%;
}

.tabs-content {
    min-height: 25vh;
    height: calc(100% - 35px);
    border: 1px solid $color_grey_3;
    padding: 0 10px;
}

.tab-content {
    border-top: 1px solid $color_grey_3;
}

.tab-pane:not(:has(.primetable-btn-container)) {
    padding-top: 10px;
}

.tabs-nav {
    width: 100%;
    .gt-nav-pills {
        flex-wrap: nowrap;
        .nav-item {
            white-space: nowrap;
            .nav-link {
                margin-bottom: -1px;
            }
        }
    }
    .intersection-observer {
        .overflow-menu-nav-item {
            height: calc(100% + 1px);
            .overflow-menu-link {
                height: 100%;
                svg {
                    transition: all 0.15s ease-in-out !important;
                }
                &.open {
                    svg {
                        transform: rotateZ(-90deg);
                    }
                }
            }
        }

        .overflow-menu-container {
            position: relative;
            .overflow-menu {
                position: absolute;
                border-radius: 3px;
                box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
                // padding: 6.5px 0;
                color: $color_text_1;
                background-color: $white;
                right: 0px;
                top: 3px;
                z-index: 1000;
                .context-menu-option {
                    // padding: 3.5px 16px 3.5px 13px;
                    &:hover {
                        background-color: rgba($color_success, 0.2) !important;
                    }
                }
            }
        }
    }

    ul {
        .invisible {
            visibility: hidden;
            order: 99;
        }

        .isActive {
            order: 0 !important;
        }
    }

    .tabs-nav-disabled {
        background: $tabs-color-disabled !important;
    }

    .nav-item {
        .active.rui-tabs-link {
            color: $gt-purple-4;
        }
        .rui-tabs-linkL:not(.disabled) {
            color: $color_dark_7;
        }
    }
}

.tabs-disabled:before {
    content: " ";
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: $tabs-color-disabled;
    animation: tabs-disabled-anim 0.3s ease-out;
}

.tabs-wrapper-disabled {
    position: relative;

    .rdt_TableRow {
        background-color: transparent !important;
    }
}

.back-button {
    width: 24px;

    & > svg {
        width: inherit;
        height: 36px;
        padding: 3px;

        background-color: $color_grey_1;
        color: $color_dark_5;
        border-left: 1px solid $color_grey_3;
        border-top: 1px solid $color_grey_3;

        transition: 0.1s;

        &:hover {
            background-color: $color_grey_2;
            color: $color_dark_4;
        }
    }
}

@keyframes tabs-disabled-anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.rui-page:has(.tabs-container.fullscreen) {
    div[class^="col"] {
        flex: auto !important;
        max-width: 100% !important;
    }

    .row {
        margin-top: 0px !important;
    }
}

.rui-page:has(.tabs-container.fullscreen) div:not(:has(.tabs-container.fullscreen)):not(.fullscreen *):not(.fullscreen) {
    display: none;
    margin: 0px !important;
}

.nav-link.active:not(.modal-content *) {
    pointer-events: visible !important;
    cursor: pointer !important;
}

.tabs-container.fullscreen {
    --btn-container-height: 38px;

    padding: 0px;
    height: calc(100vh - 170px) !important;
    width: 100%;

    .prime-table-container {
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .prime-table-container {
        // height: 100% !important;

        & > .p-datatable.p-datatable-sm {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            & > .p-datatable-wrapper {
                height: unset !important;
            }

            & > .p-datatable-scrollable-wrapper {
                display: flex;
                flex-grow: 1;

                & > .p-datatable-scrollable-view {
                    min-height: unset;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;

                    & > .p-datatable-scrollable-header:has(.p-filter-column) + .p-datatable-scrollable-body {
                        max-height: calc(100vh - 337px - var(--btn-container-height)) !important;
                    }

                    & > .p-datatable-scrollable-body {
                        max-height: calc(100vh - 304px - var(--btn-container-height)) !important;
                        min-height: unset !important;
                        display: flex;
                        flex-grow: 1;

                        & > .p-datatable-scrollable-body-table {
                            max-height: calc(100vh - 304px - var(--btn-container-height)) !important;

                            height: 31px;
                        }
                    }
                }
            }
        }
    }
}

.context-menu-dummy-container {
    position: relative;
    .context-menu-container {
        animation: filter-fade-in 0.2s ease-out;
        line-height: 1;
        position: absolute;
        z-index: 1000;
        top: 37px;
        background-color: $white;
        padding: 0.25rem 0;
        white-space: nowrap;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .context-menu-item {
        padding: 0.75rem 1rem;
        .rui-icon {
            height: 13px;
            width: 13px;
            margin-right: 0.5rem;
            color: $color_secondary;
        }
        &:hover {
            background-color: $context-hover-item;
            color: $white;
            font-weight: 600;
            svg {
                color: $white;
            }
        }
    }
}
