@import "variables";

$border: 1px solid $color_separator !default;

.ql-toolbar,
.ql-container {
    border: $border !important;
}

.ql-editor {
    height: 200px !important;
}

.gt-rich-text-input {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:has(.ql-toolbar) .ql-container {
        border-top: none !important;
    }
}

.ql-picker-options {
    margin-top: 2px !important;
    border-radius: 5px;
}
//buttons
.ql-formats {
    button {
        &:hover,
        &:focus,
        &.ql-active {
            background-color: $color_separator;
            .ql-fill {
                fill: $color_brand !important;
            }
            .ql-stroke {
                stroke: $color_brand !important;
            }
        }
    }
}
//color-picker
.ql-color-picker {
    .ql-active,
    &:hover,
    &:focus {
        background-color: $color_separator;
        polyline.ql-stroke,
        line.ql-stroke:not(.ql-transparent) {
            stroke: $color_brand !important;
        }
    }
}
//dropdowns
.ql-picker:not(.ql-color-picker) {
    .ql-picker-label {
        &.ql-active,
        &:hover,
        &:focus {
            background-color: $color_separator !important;
            color: $color_brand !important;
            .ql-fill {
                fill: $color_brand !important;
            }
            .ql-stroke {
                stroke: $color_brand !important;
            }
        }
    }
    .ql-picker-options {
        svg {
            padding: 3px;
        }
        .ql-picker-item {
            padding: 0px;
            &:hover {
                color: $color_brand !important;
            }
            :hover .ql-stroke {
                stroke: $color_brand !important;
            }
        }
        .ql-selected {
            color: $color_brand !important;
        }
    }
}
.ql-picker-label {
    display: flex !important;
}

.ql-color-picker {
    .ql-picker-options {
        display: grid !important;
        grid-template-columns: repeat(10, 1fr);
        width: auto !important;
        &[aria-hidden="true"] {
            display: none !important;
        }
        .ql-picker-item {
            border-radius: 50%;
            border: $border;
            margin: 1px !important;
            width: 18px;
            height: 18px;
        }
    }
}
