@import "variables";
@import "variables-granteam";

.global-search {
    display: inline-flex;
    align-items: center;
    background-color: $color_grey_1;
    border-radius: 18px;
    max-height: 36px;
    width: 100%;

    .btn-custom-round {
        height: 36px;
        width: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .search-modal-container {
        margin: 14rem auto;
        @media screen and (max-width: 1200px) {
            margin: 0 auto;
        }
    }

    .gs-dropdown-container {
        position: relative;
        .gs-dropdown {
            position: absolute;
            background-color: $white;
            top: 32px;
            width: 350px;
            border: 1px solid $color_separator;
            border-radius: 5px;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
            display: flex;
            flex-direction: column;
            max-height: 80vh;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-thumb {
                border: none;
                background-color: $color_grey_4;
            }

            .gs-dropdown-key {
                font-size: 0.82rem;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 1px;
                padding: 0.4rem 0 0.3rem 0.5rem;
                margin: 0px;
                &:not(:first-of-type) {
                    border-top: 1px solid $color_separator;
                }
            }
        }
    }
}

.navbar-search-container,
.search-input-container {
    width: 100%;

    &::after {
        content: " ";
        position: relative;
        display: flex;
        height: 1px;
        width: 0;
        top: -5px;
        left: 0px;
        background-color: $color_brand;
        transition: 0.2s all ease-in-out;
        z-index: 1003;
    }

    &:has(.navbar-search:focus)::after {
        width: 85%;
    }
    .navbar-search,
    .modal-search {
        padding-left: 0;
        background-color: transparent;
        width: 100%;
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }

    &:has(.modal-search:focus)::after {
        width: 15%;
    }
}

.results {
    margin-top: 1rem;
}

.result-link {
    &:hover,
    &:focus {
        background-color: $color_grey_3;
    }
    .result-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        transition: all 0.1s ease-in-out;
        padding: 1rem;
        border-radius: 5px;
        background-color: inherit;
        color: $color_text_1;

        div {
            overflow: hidden;
        }
        .result-key {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            font-size: 0.82rem;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .result-item {
            margin: 0;
            font-size: 1.1rem;
            cursor: pointer;
            font-weight: 600;
        }

        .result-match {
            font-size: 0.9rem;
            line-height: 17px;
            display: flex;
            flex-direction: column;
            max-width: 100%;

            .highlight {
                font-weight: 800;
                color: $gt-purple-3;
                text-decoration: underline;
            }
            span,
            span * {
                display: inline-flex;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

.no-search-results {
    padding: 1rem;
    font-size: 1.1rem;
    display: inline-flex;
    flex-direction: column;

    .no-search-results-icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 1rem;
    }
}
