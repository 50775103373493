.chem-viewer {
    border: 1px solid #e6ecf0;
    border-radius: 0.25rem;
    cursor: pointer;

    &.readonly {
        cursor: default;
    }
}

.chem-result {
    padding: 15px;
    margin: 10px;
    //float:left;
    //width:28%;

    .chem-details {
        word-break: break-word;
        white-space: break-spaces;
        font-size: 0.9rem;
        text-align: center;
    }
}

.kekule-filter {
    max-height: 32px !important;
    height: 32px;
    position: relative;
    cursor: pointer !important;

    input {
        cursor: pointer !important;
    }

    span {
        background-color: inherit;
    }

    .chem-viewer {
        height: 0px !important;
        border: none;
        overflow: hidden;
    }
}
