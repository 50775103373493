@import "variables";

.creatable-select .css-yk16xz-control {
    border-color: $color_separator !important;
    padding-left: 9px;
    color: #495057 !important;
}
.creatable-select .css-1pahdxg-control {
    padding-left: 9px;
    box-shadow: 0 0 0 0.2rem #2fc78740 !important;
    border-color: #2fc78790 !important;
}
