@import "variables";

.gt-navbar-dropdown-toggle {
    .navbar-dropdown-toggle {
        position: relative;
        color: $color_grey_6;
        svg {
            height: 20px;
            width: 20px;
        }
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border-radius: 50%;
        width: 35px;
        height: 35px;

        @include center-flex;

        &:hover {
            background-color: $color_grey_4;
            color: $color_text_1;
        }

        .navbar-dropdown-toggle-badge {
            display: block;
            position: absolute;
            background-color: $gt-purple-3;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            outline: 2px solid $white;
            font-size: 10px;
            font-weight: 700;
            top: 0px;
            right: -5px;
            color: white;
            line-height: 15px;
            text-align: center;
            user-select: none;
        }
    }
}
