$gt-green-1: #31c285 !default;
$gt-green-2: #2baf78 !default;
$gt-green-3: #279c6b !default;

// $gt-green-1: rgb(240, 102, 102) !default;
// $gt-green-2: rgb(226, 37, 37) !default;
// $gt-green-3: rgb(177, 13, 13) !default;

$gt-purple-0: #c5b9f7 !default;
$gt-purple-1: #a898ec !default;
$gt-purple-2: #9381db !default;
$gt-purple-3: #7d6ac8 !default;
$gt-purple-4: #6754b3 !default;
$gt-purple-5: #5542a1 !default;
$gt-purple-6: #44318d !default;
$gt-purple-7: #2f1f6e !default;
$gt-purple-8: #201255 !default;

// $gt-purple-0: rgb(247, 105, 105) !default;
// $gt-purple-1: rgb(236, 69, 69) !default;
// $gt-purple-2: rgb(226, 37, 37) !default;
// $gt-purple-3: rgb(199, 21, 21) !default;
// $gt-purple-4: rgb(170, 12, 12) !default;
// $gt-purple-5: rgb(122, 6, 6) !default;
// $gt-purple-6: rgb(78, 3, 3) !default;
// $gt-purple-7: rgb(49, 1, 1) !default;
// $gt-purple-8: rgb(36, 0, 0) !default;

$gt-border-color: rgba($gt-green-2, 0.5);
$gt-form-box-shadow: 0 0 0 0.2rem rgba($gt-green-2, 0.2) !important;
$gt-box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.12);

@function gtFormBoxShadowFn($color, $transparency: 0.2) {
    @return 0 0 0 0.2rem rgba($color, $transparency);
}

@mixin center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin space-between-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin hover($color) {
    &:hover {
        background-color: $color;
    }
}
