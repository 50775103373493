@import "variables";

.help-context-menu {
    margin: 0;
    padding: 1px;
    list-style: none;
    display: inline-block;
    display: flex;
    flex-direction: row;
    gap: 10px;

    &:hover {
        background-color: $context-hover-item;
        color: white;
        cursor: pointer;
    }
}
